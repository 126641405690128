(($) => {
  "use strict";

  $(() => {
    var node = $("#sfu-carousel");

    $(node)
      .imagesLoaded()
      .always(() => {
        $("#sfu-slider-images").slick({
          autoplay: true,
          autoplaySpeed: 10000,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: false,
          swipe: false,
          arrows: true,
          dots: false,
        });
        $("#sfu-slider-title").slick({
          autoplay: true,
          autoplaySpeed: 10000,
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: "#sfu-slider-images",
          draggable: false,
          swipe: false,
          arrows: false,
          dots: false,
        });
        $("#sfu-slider-images").slick("slickGoTo", 0);
      });
  });
})(jQuery);
